import React, { Component } from "react"




/* Component Logo */
class Logo extends Component {

    constructor() {
        super()

        this.state = {
            time: new Date().toLocaleTimeString()
        }

        this._timing = null
    }

    componentDidMount = () => {
        this._timing = setInterval(() => this.setState({ time: new Date().toLocaleTimeString() }), 1000)
    }

    componentWillUnmount = () => {
        if (this._timing) {
            clearInterval(this._timing)
            this._timing = null
        }
    }

    render = () => {

        const { time } = this.state

        return (
            <div className="game-top">

                {/* Draw logo */}
                <div className="game-logo">

                    <img src="/images/icon.png" alt="ICON" />

                    <div className="game-logo-text">
                        <span>MULTI</span>
                        <p>OPEN</p>
                        <p>POKER</p>
                    </div>

                </div>

                {/* Draw time */}
                <div className="clock">{time}</div>

            </div>
        )
    }

}

export default Logo