const point = "https://ompb.rekopgames.com"
const mediapoint = "https://cb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_MIDDLEWARE_TOKEN = "7d90302e-c234-42a4-bc30-5a7bd3caa983"

/* DEALER MONITOR TOKEN */
const DEALER_MONITOR_TOKEN = "f9ef079f-6531-4cbc-8258-15af33bca218"

/* POINTS */
const startpoint = `${point}/api/`

export { point, mediapoint, startpoint, DEALER_MONITOR_TOKEN, DEALER_MIDDLEWARE_TOKEN }
